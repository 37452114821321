import client from "graphql/client";
import { loader } from "graphql.macro";


const updateTranslationsMutation =  loader("../../graphql/mutations/updateTranslations.graphql");

export function updateTranslationsAction(translations, metadata) {
    return async (dispatch) => {
        try {
            const response = await client.mutate({
                mutation: updateTranslationsMutation,
                variables: {
                    input: {
                        translations,
                        metadata,
                    },
                },
            });

            return response.data.updateTranslations.successMessage;
        } catch (error) {
            console.error("Error updating translations:", error);
            throw error;
        }
    };
}