import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SearchInput = ({ onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="field">
            <div className="control has-icons-right">
                <input className="input" onChange={(event) => onChange(event)} type="search" placeholder={t("translation.search")} />
                <span className="icon is-right">
                    <i className="fas fa-search" />
                </span>
            </div>
        </div>
    );
}

SearchInput.propTypes = {
    onChange: PropTypes.func
};

export default SearchInput;
