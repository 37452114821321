import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";
import Icon, { ICONS } from "shared/components/Icon";
import ModuleItem from "./ModuleItem";
import { clearSiteData } from "shared/helpers/clearSiteData";
import "./style.scss";

const Modules = ({ settings, organisation }) => {

    const { t } = useTranslation();
    const [showModules, setShowModules] = useState(false);
    const { modules } = organisation || {};
    let prefix = "";
    if (window.location.hostname.includes("acceptance")) {
        prefix = "acceptance.";
    }

    const iconClasses = classNames(
        "top-menu-icon",
        showModules && "selected"
    );

    return (
        <div
            onMouseLeave={() => setShowModules(false)}
            onMouseOver={() => setShowModules(true)}
        >
            <div className={iconClasses}>
                <Icon name={ICONS.APPS} />
            </div>
            {showModules &&
                <div className="modules-drop">
                    <div className="modules-container">
                        <div className="modules-inner-container">
                            <div className="modules-header">
                                <span>{t("modules.modules.title")}</span>
                            </div>
                            <ModuleItem
                                label={t("modules.okcomply.label")}
                                description={t("modules.okcomply.description")}
                                path={`https://${prefix?.length > 0 ? prefix : "my."}okcomply.com`}
                                image={require("shared/img/module-administratie.webp")}
                                organisationId={organisation?.id}
                            />
                            <ModuleItem
                                label={t("modules.organisation.label")}
                                description={t("modules.organisation.description")}
                                path={`https://${prefix}organisation.okcomply.com`}
                                image={require("shared/img/module-beheer.svg")}
                                organisationId={organisation?.id}
                            />
                            <ModuleItem
                                label={t("modules.audit.label")}
                                description={t("modules.audit.description")}
                                path={`https://${prefix}audit.okcomply.com`}
                                image={require("shared/img/module-audit.svg")}
                                disabled={!modules?.okAudit || modules.okAudit !== "ACTIVE"}
                                organisationId={organisation?.id}
                            />
                            <ModuleItem
                                label={t("modules.publish.label")}
                                description={t("modules.publish.description")}
                                path={`https://${prefix}publish.okcomply.com`}
                                image={require("shared/img/module-bibliotheek.svg")}
                                disabled={!modules?.okPublish ||  modules.okPublish !== "ACTIVE"}
                                organisationId={organisation?.id}
                            />
                        </div>
                        {settings &&
                            <div className="modules-inner-container">
                                <div className="modules-header">
                                    <span>{t("modules.settings.title")}</span>
                                </div>
                                {settings.map((setting) => {
                                    return (
                                        <ModuleItem
                                            key={setting.path}
                                            label={setting.label}
                                            description={setting.description}
                                            path={setting.path}
                                            icon={setting.icon}
                                        />
                                    );
                                })}

                            </div>
                        }
                        <div className="container-logout">
                            <ButtonAnchor
                                label={t("modules.logout.label")}
                                secondary
                                onClick={() => {
                                    clearSiteData();
                                    window.location = "/login";
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

Modules.propTypes = {
    settings: PropTypes.object,
    organisation: PropTypes.object
};
export default Modules;