import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { localeDate, localeTime } from "helpers/formattedDate";
import Icon, { ICONS } from "shared/components/Icon";
import formatUsername from "helpers/formatUsername";
import classnames from "classnames";
import FieldSet from "components/FieldSet";
import {
    getEndDate,
    getInvoiceToAsEmailAddress,
    getInvoiceToAsFullname,
    getMonthlyAmount,
    getNumberOfBranches,
    getNumberOfOpenInvites,
    getNumberOfUsers,
    getStartDate,
    getTotalAmount,
    hasModule
} from "helpers/billing";
import { moduleKinds, modules } from "constants/module";

import Button from "shared/components/buttons/Button";
import addToOrganisationAction from "redux/actions/addToOrganisationAction";
import fetchGraphQLErrors from "helpers/fetchGraphqlErrors";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { useDispatch } from "react-redux";
import { organisationUpdateOrganisation } from "redux/actions";

import "./style.scss";

function formatToTwoDecimals(number) {
    return number.toFixed(2);
}

export default function TabInfo({ organisation }) {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [added, setAdded] = useState(false);

    const renderOwner = () => {

        let email = "";
        let fullName = "";
        const lastLoginDate = organisation?.lastLogin ? localeDate(organisation.lastLogin): "";
        const lastLoginTime = organisation?.lastLogin ? localeTime(organisation.lastLogin): "";
        const lastLoginText = `${t("last.login")} : ${lastLoginDate} ${lastLoginTime}`;

        if (organisation?.owner) {
            const { owner } = organisation;
            fullName = formatUsername(owner);
            email = owner.email;
            if (email === fullName) {
                fullName = "";
            }
        }

        return (
            <div className="field-set">
                <label className="label">
                    {t("organisation.owner.singular")}
                </label>
                <div>{fullName}</div>
                <div>{email}</div>
                <div>{lastLoginText}</div>
            </div>
        );
    };

    const renderAuditModule = () => {

        let totalAmount = 0;

        if (organisation?.billing) {
            const { billing } = organisation;
            totalAmount = getMonthlyAmount(billing, moduleKinds.OkAudit);
        }

        const strTotalAmount = `€ ${formatToTwoDecimals(totalAmount)}`;


        const className = classnames("module",
            !hasModule(organisation, moduleKinds.OkAudit)
            && "in-active");

        return (
            <div className="cols">
                <div className="col">
                    <div className={className}>
                        <div className="left">
                            <div className="field-set dark">
                                <label className="label dark">
                                    {modules.OK_AUDIT}
                                </label>
                            </div>
                        </div>
                        <div className="right">
                            {strTotalAmount}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOkComplyModule = () => {

        let totalAmount = 0;
        let numberOfBranches = 0;
        let monthlyAmount = 0;

        if (organisation?.billing) {

            const { billing } = organisation;
            numberOfBranches = getNumberOfBranches(organisation);
            monthlyAmount = getMonthlyAmount(billing, moduleKinds.OkComply);
            totalAmount = monthlyAmount * numberOfBranches;
        }

        const strTotalAmount = ` € ${formatToTwoDecimals(totalAmount)}`;

        const value = `${numberOfBranches} (${numberOfBranches} x € ${formatToTwoDecimals(monthlyAmount)})`;

        const className = classnames("module",
            !hasModule(organisation, moduleKinds.OkComply) && "in-active");


        return (
            <div className="cols">
                <div className="col">
                    <div className={className}>
                        <div className="left">
                            <div className="field-set dark">
                                <label className="label dark">{modules.OK_COMPLY}</label>
                                <div className="monthly-billing">
                                    <Icon name={ICONS.BUILDING} />
                                    {value}
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            {strTotalAmount}
                        </div>
                    </div>
                </div>
            </div>
        );


    };

    const renderPublishModule = () => {

        let totalAmount = 0;

        if (organisation?.billing) {

            const { billing } = organisation;
            totalAmount = getMonthlyAmount(billing, moduleKinds.OkPublish);
        }

        const strTotalAmount = ` € ${formatToTwoDecimals(totalAmount)}`;

        const className = classnames("module",
            !hasModule(organisation, moduleKinds.OkPublish) && "in-active");


        return (
            <div className="cols">
                <div className="col">
                    <div className={className}>
                        <div className="left">
                            <div className="field-set dark">
                                <label className="label dark">{modules.OK_PUB}</label>
                            </div>
                        </div>
                        <div className="right">
                            {strTotalAmount}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderBillingInfo = () => {

        let startDate = "";
        let endDate = "";
        let fullName = "";
        let totalAmount = 0;
        let email = "";

        if (organisation?.billing) {

            const { billing } = organisation;

            startDate = getStartDate(billing);
            endDate = getEndDate(billing);
            fullName = getInvoiceToAsFullname(billing);
            email = getInvoiceToAsEmailAddress(billing);
            totalAmount = formatToTwoDecimals(getTotalAmount(organisation));
        }

        const className = classnames("billing-info",
            organisation?.billing === null && "in-active");

        return (
            <div className={className}>
                <div className="cols">
                    <div className="col">
                        <div className="field-set">
                            <label className="label dark">
                                {t("billing.monthly.title")}
                            </label>
                            <div>
                                {t("billing.invoiceTo.label")}
                                {" "}
                                {`${fullName} (${email})`}
                            </div>
                            <div>
                                {t("billing.startDate")}
                                {" "}
                                {startDate}
                            </div>
                            <div>
                                {t("billing.endDate")}
                                {" "}
                                {endDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="horizontal-divider" />
                {renderOkComplyModule()}
                <div className="horizontal-divider" />
                {renderAuditModule()}
                <div className="horizontal-divider" />
                {renderPublishModule()}
                <div className="horizontal-divider" />
                <div className="cols">
                    <div className="col">
                        <div className="module">
                            <div className="left">
                                <span className="bold">
                                    {t("billing.total")}
                                </span>
                            </div>
                            <div className="right">
                                <span className="bold">
                                    {`€ ${totalAmount}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);

    };

    const renderUsersInfo = () => {

        let numberOfUsers = 0;
        let numberOfOpenInvites = 0;
        let numberOfBranches = 0;

        if (organisation) {

            numberOfUsers = getNumberOfUsers(organisation);
            numberOfOpenInvites = getNumberOfOpenInvites(organisation);
            numberOfBranches = getNumberOfBranches(organisation);
        }

        const activeUsers = `${numberOfUsers} ${t("organisation.users.active")}`;
        const openInvites = `${numberOfOpenInvites} ${t("organisation.users.openInvites")}`;
        const nrBranches = `${numberOfBranches} ${t("organisation.numberOfBranches")}`;

        return (
            <div className="field-set">
                <label className="label">{t("billing.users")}</label>
                <div>{activeUsers}</div>
                <div>{openInvites}</div>
                <div>{nrBranches}</div>
            </div>
        );
    };

    const renderAgreements = () => {

        let agreements = "";

        if (organisation?.billing) {

            const { billing } = organisation;
            agreements = billing.agreements;
        }

        return (
            <FieldSet label={t("billing.agreements.label")}
                value={agreements} />
        );
    };

    const addToOrganisation = async () => {
        try {
            const result = await dispatch(addToOrganisationAction(organisation.id));
            if (result) {
                const { data } = result;
                const { addToOrganisation } = data;
                setAdded(true);
                dispatch(organisationUpdateOrganisation(addToOrganisation));
                dispatch(snackbarSuccess("success"));
            }
        } catch(error) {
            const errorMessage = fetchGraphQLErrors(error);
            dispatch(snackbarError(errorMessage));
        }
    };

    const renderButton = () => {
        const { shadow } = organisation;
        return shadow === false && !added &&
            <div className="shadow-button">
                <Button
                    label={t("organisation.add.me")}
                    secondary
                    onClick={() => addToOrganisation()} />
            </div>;
    };

    return  organisation && (
        <div className="admin-info">
            <div className="tab-info">
                <div className="tab-content">
                    <div className="cols">
                        <div className="col two-third">
                            <div className="cols">
                                <div className="col">
                                    {renderOwner()}
                                </div>
                                <div className="col">
                                    {renderUsersInfo()}
                                </div>
                            </div>
                            <div className="cols">
                                <div className="col">
                                    {renderAgreements()}
                                </div>
                            </div>
                        </div>
                        <div className="col small" />
                        <div className="col one-third">
                            {renderBillingInfo()}
                        </div>
                    </div>
                    <div className="horizontal-divider-gray" />
                </div>
            </div>
            {renderButton()}
        </div>)
    ;
}

TabInfo.propTypes = {
    organisation: PropTypes.object
};