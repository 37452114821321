export const MENU_ITEMS = [{
    label: "admin.menu.organisations",
    path: "/organisations",
    iconName: "sitemap",
},
{
    label: "admin.menu.translations",
    path: "/translations",
    iconName: "globe",
}
];