import { localeDate } from "helpers/formattedDate";
import formatUsername from "helpers/formatUsername";
import { moduleRequestStatuses, moduleKinds } from "constants/module";
import moment from "moment";

export function getStartDate(billing) {
    if (billing && billing.startDate) {
        return localeDate(billing.startDate);
    }
    return "";
}

export function getEndDate(billing) {
    if (billing && billing.endDate) {
        return localeDate(billing.endDate);
    }
    return "";
}

export function getInvoiceToAsFullname(billing) {
    if (billing && billing.invoiceTo) {
        return formatUsername(billing.invoiceTo);
    }
    return "";
}

export function getInvoiceToAsEmailAddress(billing) {
    if (billing && billing.invoiceTo) {
        return billing.invoiceTo.email;
    }
    return "";
}

// okAudit 12 okcomply branches * monthlyAmount + okpub branches
export function getTotalAmount(organisation) {

    let totalAmount = 0;

    const { billing } = organisation;

    if (hasModule(organisation, moduleKinds.OkComply)) {
        if (billing &&
            billing?.monthlyAmount &&
            typeof billing.monthlyAmount[moduleKinds.OkComply] === "number" &&
            organisation.numberOfBranches) {
            totalAmount += billing.monthlyAmount[moduleKinds.OkComply] *
                organisation.numberOfBranches;
        }
    }
    if (hasModule(organisation, moduleKinds.OkAudit)) {
        if (typeof billing.monthlyAmount[moduleKinds.OkAudit] === "number") {
            totalAmount += billing.monthlyAmount[moduleKinds.OkAudit];
        }

    }
    if (hasModule(organisation, moduleKinds.OkPublish)) {
        if (typeof billing.monthlyAmount[moduleKinds.OkPublish] === "number") {
            totalAmount += billing.monthlyAmount[moduleKinds.OkPublish];
        }
    }

    return totalAmount;
}

export function getMonthlyAmount(billing, module) {
    if (billing && billing.monthlyAmount) {
        return typeof billing.monthlyAmount[module] !== "undefined" ?
            billing.monthlyAmount[module]: 0;
    }
    return 0;
}

export function getNumberOfBranches(organisation) {
    if (organisation?.numberOfBranches) {
        return organisation.numberOfBranches;
    }
    return 0;
}

export function getNumberOfUsers(organisation) {
    if (organisation?.numberOfUsers) {
        return organisation.numberOfUsers;
    }
    return 0;
}

export function getNumberOfOpenInvites(organisation) {
    if (organisation?.numberOfOpenInvites) {
        return organisation.numberOfOpenInvites;
    }
    return 0;
}

export function hasModule(organisation, module) {

    if (organisation?.modules) {

        const { modules } = organisation;

        switch(module) {
            case moduleKinds.OkComply:
                return typeof modules[moduleKinds.OkComply] !== "undefined" &&
                    modules[moduleKinds.OkComply] === moduleRequestStatuses.ACTIVE;
            case moduleKinds.OkAudit:
                return typeof modules[moduleKinds.OkAudit] !== "undefined" &&
                    modules[moduleKinds.OkAudit] === moduleRequestStatuses.ACTIVE;
            case moduleKinds.OkPublish:
                return typeof modules[moduleKinds.OkPublish] !== "undefined" &&
                modules[moduleKinds.OkPublish] === moduleRequestStatuses.ACTIVE;
            default:
                return false;
        }
    }

    return false;
}


// Example: endDate is 5 oct, then 5 oct plus one month is 5 november,
// then take the first of the next month which is 1 december
// after that the organisation can be deleted
export function calculateRemovalDate(endDate) {

    if (!endDate) return false;

    const newDate = moment(endDate).add(2, "M");

    const stopDate = moment({
        year: newDate.year(),
        month: newDate.month(),
        day: 1
    });

    return new Date() > stopDate;
}
