import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import isAuthenticated from "shared/authentication/isAuthenticated";
import Navigation from "./components/navigation/index";
import { fetchUserProfileAction } from "redux/actions/fetchUserProfile";
import Authentication from "shared/authentication/Authentication";
import Organisations from "containers/Organisations/index";
import Translations from "containers/TranslationManager/index";
import OrganisationDetails from "containers/OrganisationDetails/index";


export default function AppRouter() {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const path = history.location.pathname;
        if (!isAuthenticated() && path !== "/login") {
            history.push("/login");
        } else if (isAuthenticated() && path === "/login") {
            history.push("/organisations");
        }
    }, [history]);

    useEffect(() => {
        const loadAll = async () => {
            await dispatch(fetchUserProfileAction());
        };
        if (isAuthenticated()) {
            loadAll();
        }
    }, [dispatch]);

    const unAuthenticatedRoutes = () => {
        return (
            <>
                <Route
                    path="/login"
                    render={() =>
                        <Authentication defaultRoute="/organisations" />
                    }
                />
            </>
        );
    };

    const authenticatedRoutes = () => {
        return (
            <div className="App">
                <Navigation />
                <Switch>
                    <Route
                        path="/organisations"
                        component={Organisations} />
                    <Route
                        path="/translations"
                        component={Translations} />
                </Switch>
                <Route
                    path="/organisations/organisation/:organisationId/:tab"
                    component={OrganisationDetails}
                />
            </div>
        );
    };

    return (
        <Switch>
            <>
                {isAuthenticated() ?
                    authenticatedRoutes() :
                    unAuthenticatedRoutes()
                }
            </>
        </Switch>
    );
}
